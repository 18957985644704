@font-face {
    font-family: 'NexaBold';
    src:url('../../assets/fonts/NexaBold.ttf.woff') format('woff'),
        url('../../assets/fonts/NexaBold.ttf.svg#NexaBold') format('svg'),
        url('../../assets/fonts/NexaBold.ttf.eot'),
        url('../../assets/fonts/NexaBold.ttf.eot?#iefix') format('embedded-opentype'); 
    font-weight: normal;
    font-style: normal;
}

.landing-screen {
    * {
        font-family: 'NexaBold', sans-serif;
    }
    
    .landing-screen {
        height: 100vh;
        width: 100vw;
    
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    
    a {
        text-decoration: none;
    }
    
    .landing-navbar {
        height: 10vh;
        width: stretch;
    
        display: flex;
        justify-content: flex-start;
        align-items: center;
    
        background-color: #fff;
    }
    
    .landing-navbar a {
        text-decoration: none;
        margin-left: 2vw;
    
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .landing-navbar__logo {
        height: 100%;
        width: 70px;
    
        margin: 0 1rem;
    
        display: flex;
        justify-content: center;
        align-items: center;
    
        cursor: pointer;
    }
    
    .landing-navbar__logo img {
        height: 100%;
        width: 100%;
    }
    
    .landing-navbar__header {
        font-size: 2rem;
        font-weight: 300;
    }
    
    .mission {
        height: 60vh;
        width: stretch;
    }
    
    .mission .video-wrapper {
        min-width: 100%;
        height: 100%;
    
        display: flex;
        justify-content: center;
        align-items: center;
    
        position: relative;
    
        overflow: hidden;
        z-index: 0;
    }
    
    .mission .video-wrapper video {
        height: 100%;
        min-width: 100%;
    
        object-fit: cover;
    }
    
    .mission .video-wrapper h1 {
        position: absolute;
        top: 100px;
        left: 50%;
        transform: translate(-50%, -50%);
    
        width: max-content;
    
        color: #462255;
        font-size: 2.5rem;
        font-weight: 400;
        text-align: center;
    
        z-index: 1;
    
        padding: 1rem 3rem;
    
        background-color: rgba(255, 255, 255, 0.5);
    }
    
    .donate-btn {
        background: #462255 url(https://donorbox.org/images/white_logo.svg) no-repeat 45px;
        color: #fff;
        text-decoration: none;
        display: inline-block;
        font-size: 24px;
        padding: 15px 45px;
        padding-left: 70px;
        border-radius: 8px;
        transition: all 0.2s ease-in-out;

        border: none;
    }
    
    .video-wrapper .donate-btn {
        font-size: 24px;
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    .section .donate-btn {
        font-size: 16px;
    }
    
    .donate-btn:hover {
        background-color: #31183c;
        box-shadow: 0 0 10px #31183c;
    }

    .section#contact-us {
        background-color: #462255;
        color: #fff;
    }

    .section#solo-img {
        background-color: #462255;
        padding: 0;

        img {
            width: 100%;
            height: 100%;

            object-fit: cover;
        
        }
    }
    
    .section {
        padding: 4rem 15%;

        overflow: hidden;

        .header {
            font-size: 2.5rem;
            font-weight: 300;
            text-align: center;
        
            margin-bottom: 2rem;
            text-transform: uppercase;
        }
        
        .header span,
        p span:not(.exclude) {
            color: #069E2D;
        }
        
        .content {
            width: 100%;
        
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            justify-content: center;
            align-items: center;
        
            margin-top: 2rem;
            margin-bottom: 2rem;

            gap: 3rem;

            .contact-form {
                grid-column: span 2;
                grid-row: span 2;
            }
            
            .donation-benifits {
                grid-column: span 2;

                font-size: 1.5rem;
                p {
                    font-weight: 300;
                    line-height: 1.5;
                
                    text-transform: uppercase;
                }

                p span {
                    color: #069E2D;
                }

                ol {
                    font-weight: 300;
                    line-height: 1.5;
                
                    text-transform: uppercase;
                }

                ol li {
                    margin-bottom: 1rem;
                }

                video {
                    width: 100%;
                    margin: 2rem auto;

                    object-fit: cover;
                }
            }

            @media screen and (max-width: 980px) {
                .donation-benifits {
                    font-size: 1rem;
                    padding: 0 1rem;
                }
            }
        }
    }

    .footer {
        display: flex;
        justify-content: center;
        align-items: center;

        z-index: 1;
    
        background-color: #462255;

        .container {
            width: 100%;
            
            display: flex;
            align-items: center;
            flex-direction: column;

            gap: 1rem;

            padding: 2rem 0;
    
            color: #fff;

            .media {
                width: max-content;
                height: 50%;
                
                display: flex;
                justify-content: space-between;
                align-items: center;

                gap: 1rem;

                .media__item {
                    width: 25px;
                    height: 25px;

                    a {
                        color: #ffffff;
                    }

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    cursor: pointer;

                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
    
    @media screen and (max-width: 980px) {
        .mission .video-wrapper h1 {
            font-size: 1.5rem;
            width: 80%;
        }
    
        .section {
            padding: 2rem 5%;

            .content {
                grid-template-columns: 1fr;
                grid-template-rows: 1fr 1fr;

                .right-part img {
                    transform: translateX(0);
                }
            }
        }
    }
}