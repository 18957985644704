// scss-docs-start theme-color-variables
$primary: #462255;
$secondary: #228B22;
$success: #28a745;
$info: #711D94FF;
$warning: #ffc107;
$danger: #dc3545;
$gray-100: #f8f9fa;
$light: #f8f9fa;
$dark: #212529;

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

@function tint-color($color, $weight) {
  @return mix(white, $color, $weight);
}

// Chameleon https://codepen.io/Rowno/pen/EVEgJb
//https://freefrontend.com/css-animated-backgrounds/
// Waves
.waves-bg-secondary {
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  overflow: auto;
  background: linear-gradient(315deg, rgb(9, 126, 26) 3%, rgb(5, 162, 5) 38%, rgb(8, 122, 24) 68%, rgb(70, 215, 70) 98%);
  animation: gradient 15s ease infinite;
  background-size: 400% 400%;
  background-attachment: fixed;
}

@keyframes gradient {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0 0;
  }
}

.waves-bg-secondary .wave {
  background: rgb(255 255 255 / 25%);
  border-radius: 1000% 1000% 0 0;
  position: fixed;
  width: 200%;
  height: 12em;
  animation: wave 10s -3s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.8;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.waves-bg-secondary .wave:nth-of-type(2) {
  bottom: -1.25em;
  animation: wave 18s linear reverse infinite;
  opacity: 0.8;
}

.waves-bg-secondary .wave:nth-of-type(3) {
  bottom: -2.5em;
  animation: wave 20s -1s reverse infinite;
  opacity: 0.9;
}

@keyframes wave {
  2% {
    transform: translateX(1%);
  }

  25% {
    transform: translateX(-25%);
  }

  50% {
    transform: translateX(-50%);
  }

  75% {
    transform: translateX(-25%);
  }

  100% {
    transform: translateX(1%);
  }
}

.waves-bg-primary {
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  overflow: auto;
  background: linear-gradient(315deg, rgb(205, 136, 234) 3%, rgb(113, 29, 148) 38%, rgb(160, 56, 203) 68%, rgb(120, 11, 166) 98%);
  animation: gradient 15s ease infinite;
  background-size: 400% 400%;
  background-attachment: fixed;
}

// Customizing Tabs
.navbar-links{
  border-radius: 0.375rem!important;
  --bs-nav-tabs-link-active-color: white!important;
  //color: white!important;
  color: var(--bs-nav-link-color);
  text-decoration: none!important;
  padding: 0 4px!important;
}
.navbar-links:focus{
  color: var(--bs-nav-link-hover-color) !important;
}
.navbar-links:hover {
  color: var(--bs-nav-link-hover-color)!important;
}
.nav-tabs{
  --bs-nav-tabs-link-active-bg: none!important;
  --bs-nav-tabs-border-color: none!important;
  //border-bottom: white !important;
  //box-shadow: 2px 1px 6px #462255;
  //width: 80%;
}
.nav-tabs .nav-link {
  border-radius: 0.375rem;
  --bs-nav-tabs-link-active-color: white!important;
  color: black;
}
.nav-tabs .nav-link.active{
  color: black!important;
  box-shadow: 4px 2px 12px $primary;
}
.event-tabs .nav-link{
  color: white!important;
}
.event-tabs .nav-link.active{
  color: white!important;
}
// Customizing Accordion
.accordion{
  --bs-accordion-bg: rgba(205, 136, 234, 0.28) !important;
  --bs-accordion-border-color: info !important;
  --bs-accordion-active-bg: none!important;
  --bs-accordion-active-color: black!important;
  --bs-accordion-btn-focus-box-shadow: none!important;
}
@media (prefers-reduced-motion: reduce){
  .accordion-button{
    transition-delay: 3s!important;
    transition-duration: 2s!important;
  }
}
// Square
//.square {
//  flex: 0 0 calc(33.33% - 20px); /* Adjust the width as needed */
//  margin: 10px;
//height: 0;
//padding-bottom: calc(33.33% - 20px); /* Adjust the height as needed */
//background-color: #6bbf59;
//}
// NavBar
.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}
.dropdown-menu{
  text-align: center!important;
  background: linear-gradient(to right, #550d73, #780ba6) !important;
  --bs-dropdown-border-width: none!important;
  --bs-dropdown-link-color: white!important;
  //--bs-dropdown-padding-x: none!important;
  --bs-dropdown-padding-y: 15px!important;
  //--bs-dropdown-min-width: none!important;
}
.profile-dropdown .dropdown-menu.dropdown-menu[data-bs-popper] {
  left: -100px;
}
.dropdown-item:hover {
  background: $info!important;
  color: white!important;
}
//.dropdown-menu[data-bs-popper] {
//  margin-top: -15 px;
//}
.dropdown-menu[data-bs-popper] {
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
  top: 70% !important;
}

// ImageListGallery
ol, ul, dl {
  margin-bottom: 0!important;
}

// Activity
.activity-item {
  margin-top: 30px;
}

.activity-item {
  height: 280px;
  position: relative;
}

.activity-item:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 100%;
  bottom: 0;
  background-color: #1eb61e;
  transition: right 0.25s ease-in-out;
  -moz-transition: right 0.25s ease-in-out;
  -webkit-transition: right 0.25s ease-in-out;
  -o-transition: right 0.25s ease-in-out;
  -ms-transition: right 0.25s ease-in-out;
}

.activity-item:hover:before {
  right: 0;
}

.activity-item-title,
.activity-item a {
  position: absolute;
  left: 0;
  margin: 0;
  font-size: 16px;
  opacity: 0;
}

.activity-item-title {
  width: 100%;
  top: 40px;
  transition: left 0.25s ease-in-out, opacity 0.25s ease-in-out;
  -moz-transition: left 0.25s ease-in-out, opacity 0.25s ease-in-out;
  -webkit-transition: left 0.25s ease-in-out, opacity 0.25s ease-in-out;
  -o-transition: left 0.25s ease-in-out, opacity 0.25s ease-in-out;
  -ms-transition: left 0.25s ease-in-out, opacity 0.25s ease-in-out;
}

.activity-item:hover .activity-item-title {
  left: 0;
  opacity: 1;
}

.activity-item a {
  bottom: 22px;
  //color: #26272d;
  //padding: 5px 12px;
  //border: 1px solid #26272d;
  //border-radius: 50px;
}

.activity-item:hover a {
  left: 50%;
  transform: translate(-50%, 0%);
  opacity: 1;
}

.activity-img{
  display: block;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

// Custom Card
.custom-card {
  margin-bottom: 20px;
  transition: transform 0.3s;
}

.custom-card:hover {
  transform: scale(1.05);
}

.custom-card img {
  height: 200px;
  object-fit: cover;
}
// Hero Overlay
.hero-overlay {
  position: relative;
  background-size: cover;
  background-position: center;
  filter: grayscale(50%);
  //background-image: through react;
  height: 400px;
}

.hero-overlay:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the overlay color and opacity as needed */
}
.hero-text {
  text-align: center;
  color: #ffffff;
  padding: 23vh 20px 0 20px;
  z-index: 1;
  position: relative;
}

// Section Underline
.border-decor {
  border-bottom: 2px solid $info;
}
.border-decor-light{
  border-bottom: 2px solid #fff;
}
.border-decor-dark{
  border-bottom: 2px solid #000;
}

// Moving Line
.moving-section {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
}

.moving-content {
  animation: move 25s linear infinite;
}

@keyframes move {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}


// Slopes
.top-slope {
  //background: #E2D3A4;
  background: $info;
}
.bottom-slope {
  background: $primary;
}
.slope-container svg {
  width: 100%;
  height: 100%;
}
.slope-container svg path {
  //fill: #E2D3A4;
  fill: $info;
}

.slope-container svg rect {
  fill: $primary;
}
.slope-container {
  --divider-height: 4rem;
  position: relative;
  top: calc( var(--divider-height)/2 * -1 );
  height: var(--divider-height);
  width: 100%;
  /* alternatively, could get rid of the rect, and move the svg container further down */
  float: left;
  z-index: 1;
}
.slope-container1 svg path {
  fill: $primary;
}

.slope-container1 svg rect {
  //fill: #E2D3A4;
  fill: $info;
}
.slope-container-out svg path {
  //fill: #E2D3A4;
  fill: $info;
}

.slope-container-out svg rect {
  fill: #fff;
}

// SVG shadows
.svg-shadow {
  filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
  -webkit-filter: drop-shadow( 3px 10px 2px rgba(0, 0, 0, .7));
  //filter: drop-shadow( 10px 0px 2px rgba(0, 0, 0, .7));
}

// Transitioning backgrounds
.transitioning-background-primary {
  background: linear-gradient(to right, $primary, $info);
  padding: 20px;
  text-align: center;
}
.transitioning-background-secondary {
  background: linear-gradient(to right, #087a18, #05a205);
  padding: 20px;
  text-align: center;
}
// Beige bg
.bg-beige{
  //background: #E2D3A4;
  background: $info;
}

// Navbar
.single-line-link {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
// Horizontal scrolling
.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;

  .scrolling-card {
    display: inline-block;
  }
}
// Images profiles
.img-profile {
  width: 200px;  /* You can adjust this value to your desired size */
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
}
.img-profile-small {
  width: 150px;  /* You can adjust this value to your desired size */
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
}
.img-profile-gallery {
  width: 150px;  /* You can adjust this value to your desired size */
  height: 150px;
  object-fit: cover;
}

.nav-group-right .navbar-links {
  margin: 0 1rem;
}

.nav-link {
  margin-top: 0;
}

@media screen and (max-width: 768px) {
  .nav-group-right {
    margin-top: 15px;
  }

  .nav-group-right .navbar-links {
    margin: 15px 1rem;
  }
  
  .nav-link {
    margin-top: 15px;
  }
}
