
.contact-form {
    width: 100%;
    color: #fff;

    .form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
        grid-column: span 2;
        grid-row: 1 / span 2;
    
        gap: 1rem;
        
        .form__group {
            width: 100%;
            text-align: left;

            .form__label {
                margin: 0 0 0.5rem 0;
            }
    
            .form__input {
                width: 100%;
                height: 50px;
    
                border: 1px solid #fff;
                color: #fff;
                background-color: transparent;
                border-radius: 8px;
    
                padding: 1rem 1.2rem;
    
                font-size: 1rem;
                font-weight: 300;
    
                outline: none;
    
                &::placeholder {
                    color: #fff;
                }
            }
    
            textarea.form__input {
                resize: vertical;
                height: 150px;
            }
    
            .form__btn {
                width: 100%;
                height: 50px;
    
                border: 1px solid #fff;
                border-radius: 8px;
                
                background-color: transparent;
                color: #fff;
    
                font-size: 1rem;
                font-weight: 300;
    
                cursor: pointer;
    
                transition: all 0.2s ease-in-out;
    
                &:hover {
                    background-color: #31183c;
                }
            }
        }
    
    }
}