.card { border-radius: 1rem; }

.card.bg-dark {
    background-color: #0000004b !important;
    .form-control {
        border-radius: 0.25rem;
        color: white;
        background-color: #ffffff00;
        border: 1px solid rgba(206, 212, 218, 0.4);
    }
    
    .form-control::-webkit-input-placeholder{
        color:white;
    }
    .form-control:-moz-placeholder {
        color:white;
    }
    
    .form-control:focus {
        border-color: #ced4da;
        box-shadow: none;
    }
    
    .btn-outline-light {
        border-radius: 0.25rem;
        font-size: 1rem;
        color: white;
        border-color: white;
        background-color: #ffffff00;
        font-weight: 600;
    }
    
    .btn-outline-light:hover {
        background-color: #0000009a;
    }
}